export class SelectHandler {

  constructor(params) {
    this.translations = params.translations;
    this.$container = params.$container || $(document);
    this.params = params;

    this.init();
  }

  init() {

    if (this.$elements.length > 0) {
      this.destroy();
    }

    $('select[data-select-url]').change((event) => {
      document.location.href = $(event.currentTarget).val();
    });

    this.$container.find('[data-select2="search"] select').each((index, element) => {
      const $element = $(element);
      const options = this.getOptions($element);
      $element.select2({
        language: {
          noResults: _ => {
            return this.translations['noResults'];
          }
        },
        width: "100%",
        closeOnSelect: options.closeOnSelect,
        allowClear: options.allowClear,
        placeholder: options.placeholder,
      });
    });

    this.$container.find('[data-select2="nosearch"] select').each((index, element) => {
      const $element = $(element);
      const options = this.getOptions($element);
      $element.select2({
        language: {
          noResults: _ => {
            return this.translations['noResults'];
          }
        },
        width: "100%",
        minimumResultsForSearch: Infinity,
        closeOnSelect: options.closeOnSelect,
        allowClear: options.allowClear,
        placeholder: options.placeholder,
      });
    });

    this.$container.find('[data-select2="ajax"] select').each((index, element) => {
      const $element = $(element);
      const options = this.getOptions($element);
      const url = $element.data("select2-url");
      const id = $element.data("select2-id");

      if (url) {
        $element.select2({
          language: {
            noResults: _ => {
              return this.translations['noResults'];
            }
          },
          width: "100%",
          closeOnSelect: options.closeOnSelect,
          allowClear: options.allowClear,
          placeholder: options.placeholder,
          ajax: {
            url: url,
            delay: $element.data("select2-delay") || 0,
            dataType: 'json',
            data: (params) => {
              return {
                search: params.term,
                page: params.page
              };
            },
            processResults: (data) => {

              if (id && this.params[`processResults_${id}`] && typeof this.params[`processResults_${id}`] === 'function') {
                return this.params[`processResults_${id}`](data);
              }

              return {
                results: $.map(data.results, (val) => {
                  return {
                    "id": val.id,
                    "text": val.text,
                  }
                }),
                pagination: data.pagination,
              }
            }
          },
        });
      }
    });
  }

  destroy() {
    this.$elements.select2("destroy");
  }

  getOptions($element) {
    return {
      closeOnSelect: $element.data("select2-close-on-select") !== false,
      allowClear: $element.data("select2-allow-clear") === true,
      placeholder: $element.data("select2-placeholder") || $element.attr("multiple") ? null : '---------',
    };
  }

  get $elements() {
    return this.$container.find('.select2-hidden-accessible');
  }

}
