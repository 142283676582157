export class CountDown {
  constructor($target, seconds = 10) {
    this.seconds = seconds;
    this.cpt = seconds;
    this.$target = $target;
    this.callback = null;
  }

  start(callback) {
    if (this._handler) {
      return;
    }

    this.callback = callback;

    if (this.seconds > 0) {

      this.scheduleCallback(callback);

      return new Promise((resolve, reject) => {
        this._handler = setInterval(_ => this.updateValue(resolve, reject), 1000);
      });
    }
  }

  stop() {
    if (this._handler) {
      clearInterval(this._handler);
      this._handler = null;
    }

    if (this._scheduledHandler) {
      clearInterval(this._scheduledHandler);
      this._scheduledHandler = null;
    }
  }

  reset() {
    this.cpt = this.seconds;
    this.setValue(this.seconds);
  }

  setValue(cpt) {
    if (cpt > 1) {
      $('[data-countdown-seconds=plural]').show();
      $('[data-countdown-seconds=singular]').hide();
    } else {
      $('[data-countdown-seconds=plural]').hide();
      $('[data-countdown-seconds=singular]').show();
    }

    this.$target.text(`${cpt}`);
  }

  updateValue(resolve, reject) {
    if (this.cpt === 0) {
      resolve(true);
      this.stop();
    } else {
      this.setValue(this.cpt);
      this.cpt = this.cpt - 1;
    }
  }

  scheduleCallback() {
    this._scheduledHandler = setTimeout(_ => {
      this.callback();
    }, this.seconds * 1000);
  }
}
