import EventClass from "./EventClass";

export class ReCaptchaManager extends EventClass {

  constructor({
                publicKey = null,
                widgetUuid = null,
                inputName = null,
              } = {}) {
    super();
    this._publicKey = publicKey;
    this._widgetUuid = widgetUuid;
    this._inputName = inputName;

    this.initialized = false;
  }

  init() {
    if (typeof grecaptcha !== "undefined" && !this.initialized) {
      grecaptcha.ready(() => {
        this.emit("grecaptcha-ready");
        grecaptcha.execute(this.publicKey, {action: "form"})
          .then((token) => {
            var element = document.querySelector(`.g-recaptcha[data-widget-uuid="${this.widgetUuid}"]`);
            element.value = token;
            this.initialized = true;
            this.emit("grecaptcha-executed");
          });
      });
    }
  }

  reset() {
    if (typeof grecaptcha !== "undefined" && this.initialized) {
      grecaptcha.reset();
    }
  }

  get publicKey() {
    if (!this._publicKey) {
      return this.$captcha.data("sitekey");
    }

    return this._publicKey;
  }

  get widgetUuid() {
    if (!this._widgetUuid) {
      return this.$captcha.data("widget-uuid");
    }

    return this._widgetUuid;
  }

  get $captcha() {
    if (this._inputName) {
      return $(`input[name="${this._inputName}"]`);
    }
  }
}
