import {modal} from "./Modal";

export class ModalHandler {

  constructor() {
    this.initEvents();
  }

  initEvents() {
    $('[data-open-modal]').click(event => {
      event.preventDefault();

      let $currentTarget = $(event.currentTarget);
      modal.load($currentTarget.attr('href'), $modal => {
        let $form = $modal.find('form');
        if ($form) {
          $form.handleAjaxSubmit();
        }
        $modal.filter('.modal').first().modal('show');
      });
    });
  }
}
