import EventClass from "./EventClass";

class Loading extends EventClass {

  constructor() {
    super();
  }

  show(loader) {
    $(`[data-hide-on-loading="${loader}"], [data-hide-on-loading*="[${loader}]"]`).hide();
    $(`[data-show-on-loading="${loader}"], [data-show-on-loading*="[${loader}]"]`).show();
    $(`[data-loader=${loader}]`).show();
    this.emit("loading", loader);
  }

  hide(loader) {
    $(`[data-hide-on-loading="${loader}"], [data-hide-on-loading*="[${loader}]"]`).show();
    $(`[data-show-on-loading="${loader}"], [data-show-on-loading*="[${loader}]"]`).hide();
    $(`[data-loader=${loader}]`).hide();
    this.emit("loaded", loader);
  }
}

const loader = new Loading();

export {loader};
