import {jsutils} from "./JSUtils";

export class DropdownHandlerV2 {
  constructor(selector = null) {
    this.$container = $(selector || document);

    this.$container.find('.dropdown__toggle').click(event => this.display(event));

    this.$container.click(event => this.hide(event));
  }

  display(event) {
    let $eventTarget = $(event.currentTarget);
    this.$container.find(`.dropdown__content:not(#${$eventTarget.data('toggle')})`).hide();
    this.$container.find(`#${$eventTarget.data('toggle')}`).toggle();
  }

  hide(event) {
    let $target = $(event.target);
    if (!jsutils.hasClass($target[0], 'dropdown__toggle') && $target.parents('.dropdown__toggle').length === 0) {
      this.$container.find('.dropdown__content').hide();
    }
  }
}
