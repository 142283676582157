import {messages} from "./Messaging";
import {cookies} from "./Cookies";

export class Ga {
  constructor(params = {}) {

    this._trackers = ["default"];
    this._remoteTrackerPromise = null;

    this.remoteTrackerInitialized = false;

    this.getRemoteTracker(params.getRemoteTracker !== false);
  }

  get enabled() {
    return typeof(ga) !== "undefined";
  }

  send(...args) {
    this.getRemoteTracker().then(_ => this._doSend(...args));
  }

  _doSend(...args) {
    if (this.enabled) {
      for (let prefix of this._trackers) {
      ga(`${prefix}.send`, ...args);
      }
    }
  }

  createTracker(trackingId, {name = "default", clientId = null} = {}) {
    if (this.enabled) {
      ga('create', {
        trackingId: trackingId,
        name: name,
        cookieFlags: "SameSite=None; Secure",
        clientId: clientId === null ? undefined : clientId,
        allowLinker: true,
      });

      this._trackers.push(name);
    }
  }

  getRemoteTracker(needed) {
    if (this._remoteTrackerPromise === null) {
      this._remoteTrackerPromise = new Promise((resolve, reject) => {

        if (!this.remoteTrackerInitialized) {
          this.remoteTrackerInitialized = true;
          if (needed) {

            if (messages.enabled) {
              messages.on('remote-tracker', response => {
                this.createTracker(response.trackingId, {name: "straceo_cw", clientId: response.clientId});
                resolve();
              });
              messages.sendMessage('remote-tracker-requested', {target: 'top'});
            } else {
              const trackingId = cookies.get("ga_rtid");

              if (trackingId) {
                this.createTracker(trackingId, {name: "straceo_cw", clientId: null});
              }
            }

          } else {
            resolve();
          }
        } else {
          resolve();
        }
      });
    }

    return this._remoteTrackerPromise;

  }

  sendVirtualPageView(location) {
    this._doSend({
      hitType: 'pageView',
      page: location
    });
  }

  sendEcommerceData(transaction, item) {
    this.getRemoteTracker().then(_ => this._doSendEcommerceData(transaction, item));
  }

  _doSendEcommerceData(transaction, item) {
    if (this.enabled) {
      for (let prefix of this._trackers) {
        ga(`${prefix}.require`, 'ecommerce');
        ga(`${prefix}.ecommerce:addTransaction`, transaction);
        ga(`${prefix}.ecommerce:addItem`, item);
        ga(`${prefix}.ecommerce:send`);
      }
    }
  }

  clearCookies() {
    cookies.del("ga_rtid");
  }

}

