export class RemoteChecker {

  constructor(url, interval = 2000, rejectOnFail = false) {
    this.interval = interval;
    this._url = url;
    this._promise = null;
    this._handler = null;

    this.rejectOnFail = rejectOnFail === true;
  }

  start() {

    if (this._promise === null) {
      this._promise = new Promise((resolve, reject) => {
        this._handler = setInterval(_ => this.check(resolve, reject), this.interval);
      });
    }
    return this._promise;
  }

  stop() {
    if (this._handler) {
      clearInterval(this._handler);
      this._handler = null;
      this._promise = null;
    }
  }

  get isStarted() {
    return this._handler !== null;
  }

  get url() {
    return this._url;
  }

  check(resolve, reject) {
    $.get(this.url).done((response, textStatus, jqXHR) => {
      if (response.success) {
        resolve(response);
        this.stop();
      }
    }).fail((jqXHR, textStatus, errorThrown) => {
      if (this.rejectOnFail) {
        reject(jqXHR);
        this.stop();
      }
    });
  }

}


