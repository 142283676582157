export class LanguageUpdate {
  constructor(params) {
    this.url = params.url;
    this.next = params.next;

    this.initEvents();
  }

  initEvents() {
    $('[data-language-code]').click(event => this.onLanguageCodeClick(event));
  }

  onLanguageCodeClick(event) {
    event.preventDefault();

    let $currentTarget = $(event.currentTarget);

    $.post(this.url, {
      "language": $currentTarget.data("language-code"),
      "next": this.next ? this.next : document.location.href,
    }).done(response => {
      if (response["redirect-url"]) {
        document.location.href = response["redirect-url"];
      }
    });
  }
}
