class JSUtils {

  hasClass(element, className) {
    return (element && element.className.indexOf(className) >= 0);
  }

  addClass(element, className) {
    if (element && !this.hasClass(element, className)) {
      element.className += ` ${className}`;
    }
  }

  removeClass(element, className) {
    if (this.hasClass(element, className)) {
      element.className = element.className.replace(new RegExp('(\\s|^)' + className + '(\\s|$)'), ' ').replace(/^\s+|\s+$/g, '');
    }
  }


}

const jsutils = new JSUtils();

export { jsutils };
