import {ReCaptchaManager} from "../utils";

export class ContactPros {
  constructor() {
    this.$form = $('#contact-pros-form');

    this.captchaManager = new ReCaptchaManager({
      inputName: "captcha",
    });

    this.initEvents();
  }

  initEvents() {

    this.$form.handleLightAjaxSubmit({
      onResponse: (success, data) => {
        if (!success) {
          this.captchaManager.init();
        }
      }
    });

  }
}
