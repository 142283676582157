import {cookies} from "./Cookies";

export class CSRFHandler {

  constructor() {

    $.ajaxSetup({
      beforeSend: (xhr, settings) => {
        if (!this.isSafeMethod(settings.type) && !settings.crossDomain) {
          xhr.setRequestHeader("X-CSRFToken", this.csrfToken);
        }
      }
    });
  }

  get csrfToken() {
    let token = cookies.get('csrftoken');
    return token;
  }

  isSafeMethod(method) {
    // these HTTP methods do not require CSRF protection
    return (/^(GET|HEAD|OPTIONS|TRACE)$/.test(method));
  }

}
