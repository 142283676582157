import {LanguageUpdate} from "../utils";
import {jsutils} from "../utils/JSUtils";
import {ContactPros} from "./ContactPros";

export class Home {

  constructor(params) {

    new LanguageUpdate({
      url: params.languageUrl
    });

    this.initEvents();

    new ContactPros();
  }

  initEvents() {
    $(document).ready(function () {
      $('html').animate({scrollTop: 0}, 1);
      $('body').animate({scrollTop: 0}, 1);
    });

    $(window).scroll(() => {
      let scroll = $(window).scrollTop();
      let $clearHeader = $(".clearHeader");

      if (scroll >= 50) {
        jsutils.addClass($clearHeader[0], "navbar-scroll");
      } else {
        jsutils.removeClass($clearHeader[0], "navbar-scroll");
      }
    });



    $('a[href^="#"]').click(function(){
        var the_id = $(this).attr("href");
        if (the_id === '#') {
            return;
        }
        $('html, body').animate({
            scrollTop:$(the_id).offset().top
        }, 'slow');
        return false;
    });




  }
}
