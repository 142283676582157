import EventClass from "./EventClass";

class Messaging extends EventClass {

  constructor() {
    super();

    try {
      this.enabled = window.self !== window.top;
    } catch (e) {
      this.enabled = true;
    }

    if (this.enabled) {
      window.addEventListener("message", message => this.onMessage(message), false);
    }
  }

  onMessage({data = {}}) {
    if (this.enabled) {
      try {
        data = JSON.parse(data);
      } catch (e) {
        data = {}
      }
      let {type = 'unknow', params = null} = data;
      this.emit(data.type, data.params);
    }
  }

  sendMessage(type, {target = 'parent', params = null} = {}) {
    if (this.enabled) {
      let recipient = target;
      if (target === 'top' || target === 'parent') {
        recipient = window[target];
      }
      recipient.postMessage(JSON.stringify({type: type, params: params}), '*');
    }
  }

}

const messages = new Messaging();

export {messages};