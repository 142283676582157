import {disabler} from "./Disabler";
import {loader} from "./Loading";

export class SimpleAjaxFormWorkflow {
  constructor(formId) {
    this.$form = $(`form#${formId}`);
    this.formId = formId;

    this.formSubmitting = false;

    this.$form.handleLightAjaxSubmit({
      onResponse: (success, data, response) => {
        this.formSubmitting = false;
        if (success) {
          this.onFormSuccess(data);
        } else {
          this.hideLoader();
          this.enable();
          this.onFormError(data, response);
        }
      },
      onError: (jqXHR, textStatus, errorThrown) => {
        this.hideLoader();
        this.enable();
        this.onAjaxError(jqXHR, textStatus, errorThrown)
      },
      beforeSubmit: ($form) => {
        this.formSubmitting = true;
        this.showLoader();
        this.disable();
        this.beforeSubmit($form);
      },
      // onProgress: (progress) => {
      //   this.onProgress(progress);
      // },
      submitAllowed: (callback) => {
        this.submitAllowed(callback);
      },
    });

  }

  onFormSuccess(data) {
    // to override
  }

  onFormError(data, response) {
    // to override
  }

  onAjaxError(jqXHR, textStatus, errorThrown) {
    // to override
  }

  submitAllowed(callback) {
    return callback(true);
  }

  beforeSubmit($form) {
    // to override
  }

  hideLoader() {
    loader.hide(`${this.formId}-submit`);
  }

  showLoader() {
    loader.show(`${this.formId}-submit`);
  }

  disable() {
    disabler.disable(`${this.formId}-disabler`);
    this.$form.find("[type=submit]").attr("disabled", "disabled");
  }

  enable() {
    disabler.enable(`${this.formId}-disabler`);
    this.$form.find("[type=submit]").removeAttr("disabled", "disabled");
  }

  submit() {
    this.$form.submit();
  }
}
