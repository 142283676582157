import EventClass from "./EventClass";

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export class AccountExists extends EventClass {
  
  constructor(url, $target) {
    super();

    this.url = url;
    this.$target = $target;
    this._emailIsValid = false;
    this._accountValidated = false;
    this._lastValue = '';

    this._promise = null;
    this._handler = null;
  }
  
  start() {
    if (this._promise === null) {
      this._promise = new Promise((resolve, reject) => {
        this._handler = setInterval(_ => this.checkValue(resolve, reject), 500);
      });
    }
    return this._promise;
  }
  
  stop() {
    if (this._handler) {
      clearInterval(this._handler);
      this._handler = null;
      this._promise = null;
    }
  }
  
  checkValue(resolve, reject) {
    let currentValue = this.$target.val();
    if (this._lastValue != currentValue) {
      let isValidEmail = this._checkEmailIsValid(currentValue);

      this.emit('email-change');
      
      if (isValidEmail) {
        $.post(this.url, {'email': currentValue}).done(response => {

          this._accountValidated = response.validated;

          if (response.exists) {
            resolve(true);
            this.stop();
          }
          this._lastValue = currentValue;
        });
      } else {
        this._lastValue = currentValue;
      }
      
    }
  }

  get isStarted() {
    return this._handler !== null;
  }
  
  get emailIsValid() {
    return this._emailIsValid;
  }

  get accountValidated() {
    return this._accountValidated;
  }
  
  _checkEmailIsValid(email) {
    this._emailIsValid = emailRegex.test(email);
    return this._emailIsValid;
  }
  
};