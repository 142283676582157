export class Disabler {

  initDisablers() {
    $("body").on("click", "[data-disable]", (event) => {
      const $currentTarget = $(event.currentTarget);
      const id = $currentTarget.data("disable") || $currentTarget.closest("[data-disable]").data("disable");
      this.disable(id);
    });
  }

  disable(id) {
    $(`[data-disabler="${id}"]`).each((index, element) => {
      const $element = $(element);
      $element.attr("disabled", "disabled");
      $element.addClass("disabled");
    });
  }

  enable(id) {
    $(`[data-disabler="${id}"]`).each((index, element) => {
      const $element = $(element);
      $element.removeAttr("disabled");
      $element.removeClass("disabled");
    });
  }
}

const disabler = new Disabler();

export {disabler};