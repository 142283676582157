class Cookies {

  get(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie != '') {
      let cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        let cookie = $.trim(cookies[i]);
        // Does this cookie string begin with the name we want?
        if (cookie.substring(0, name.length + 1) == (name + '=')) {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }

  set(name, value, days, path = '/') {
    let expires;
    if (days) {
      let date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = `expires=${date.toGMTString()};`;
    } else {
      expires = "";
    }
    document.cookie = `${name}=${value};${expires} path=${path}`;
  }

  del(name) {
    this.set(name, "");
  }

}

const cookies = new Cookies();

export {cookies};
